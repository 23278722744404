import React from 'react';

function SectionOne() {

    return (
        <div className="section-one__container--outer">
            <div className="section-one__container--inner">
                <div className="section-one__sidebar">

                </div>
                <div className="section-one__content">

                </div>
            </div>
        </div>
    )
}

export default SectionOne;