import  React from 'react';

function SectionTwo() { 
    return (
        <div className="section-two__container">
            <div className="section-two__container--left">
                
            </div>
            <div className="section-two__container--right">

            </div>
        </div>

        
    )
}

export default SectionTwo;