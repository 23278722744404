import React from 'react';

function SectionFour() {

    return (
        <div className="section-four__container">
            <div className="section-four__content">

            </div>
        </div>
    )
}

export default SectionFour;