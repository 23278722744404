import  React from 'react';

function SectionThree() { 
    return (
        <div className="section-three__container">
            <div className="section-three__container--left">
                
            </div>
            <div className="section-three__container--right">

            </div>
        </div>

        
    )
}

export default SectionThree;