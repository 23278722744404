import React from 'react';

function Hero() {

    return (

        <div className="hero__container">
            <div className="hero-cta__container">
                
            </div>
        </div>

    )
}

export default Hero