import React from 'react';

function Footer() {

    return (
        <div className="footer__container">
            <div className="footer__content--container">
                <div className="footer__content--left">

                </div>
                <div className="footer__content--right">

                </div>
            </div>
            <div className="footer__bottom-bar">

            </div>
        </div>

    )
}

export default Footer;