import React from "react";

function Topbar() {

    return (

        <div className="topbar__container">
            <div className="topbar__logo">

            </div>
            <div className="topbar__navigation">

            </div>
        </div>

)

}

export default Topbar;